import React from "react";

import SpotlightContext from "components/SpotlightContext";

function resultDetailLine(resultDetail){
  // console.log(resultDetail);
  if (resultDetail.details.group === 'link'){
    return "Navigate to: " + resultDetail.details.destination;
  } else if (resultDetail.details.group === 'promo'){
    return "Get Navigator on your website!";
  } else if (resultDetail.details.group === 'social'){
    return "Connect with us on our social channel";
  }
  return "...";
}

export default class ResultDetails extends React.Component {
  render (){
    return (
      <SpotlightContext.Consumer>
        {({ searchResults, selectedResultIndex , selectResult, performOperation, theme }) => (
          <div className="result-details text-black bg-dark-white">
            {resultDetailLine(searchResults[selectedResultIndex]) || " "}
          </div>
        )}
      </SpotlightContext.Consumer>
    )
  }
};
