import {_self_promo} from './common';


var tagline = encodeURIComponent('DivShow: Build highly effective product walkthroughs in minutes');
var base = encodeURIComponent('https://divshow.bytebeacon.com/');


const _internal = [
  {
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/"
    }, 
    "label": "Home",
    "keywords": [
      'root'
    ]
  }, {
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/faq"
    }, 
    "label": "Frequently asked questions",
    "keywords": [
      "faq", "questions",
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/login"
    }, 
    "label": "Login",
    "keywords": [
      "login", "signup",
    ]
  }, {
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/pricing"
    }, 
    "label": "Pricing",
    "keywords": [
      "price", "billing", "cost",
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/legal/tos"
    }, 
    "label": "Terms of Service",
    "keywords": [
      "tos", "terms"
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/legal/privacy"
    }, 
    "label": "Privacy Policy",
    "keywords": [
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/how"
    }, 
    "label": "How it works",
    "keywords": [
      "integrations", "instructions"
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/instructions"
    }, 
    "label": "Instructions",
    "keywords": [
      "docs", "api", "documentation",
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/use_cases"
    }, 
    "label": "Use Cases",
    "keywords": [
      "categories", "features", "register",
    ]
  }, {
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://divshow.bytebeacon.com/analytics"
    }, 
    "label": "Analytics",
    "keywords": [
      "usage", "insights", "data", "metrics",
    ]
  },
]


const _external = [
  {
    "details": { 
      "group": "link", 
      "sub": "external", 
      "destination": "https://betalist.com/startups/divshow"
    }, 
    "label": "on BetaList",
    "keywords": [
      "listing", "featured on"
    ]
  }, {
    "details": { 
      "group": "link", 
      "sub": "external", 
      "destination": "https://www.producthunt.com/posts/divshow"
    }, 
    "label": "on Product Hunt",
    "keywords": [
      "listing", "featured on"
    ]
  }, {
    "details": { 
      "group": "link", 
      "sub": "external", 
      "destination": "https://www.indiehackers.com/product/divshow"
    }, 
    "label": "on Indie Hacker",
    "keywords": [
      "listing", "featured on"
    ]
  },
]

const _social = [
  {
    "details": { 
      "group": "social", 
      "sub": "tweet", 
      "destination": "https://twitter.com/intent/tweet?hashtags=ByteBeacon,DivShow&original_referer=" + base + '&text=' + tagline + '&tw_p=tweetbutton&url=' + base + "&via=catalysticinfo"
    }, 
    "label": "Tweet about #DivShow",
    "keywords": [
      "social", "twitter", "announce", "promote"
    ]
  }, {
    "details": { 
      "group": "social", 
      "sub": "twitter", 
      "destination": "https://twitter.com/intent/follow?screen_name=catalysticinfo"
    }, 
    "label": "Follow on Twitter",
    "keywords": [
      "announce", "promote",
    ]
  }, {
    "details": { 
      "group": "social", 
      "sub": "reddit", 
      "destination": "https://www.reddit.com/user/catalysticinfo"
    }, 
    "label": "Connect on Reddit",
    "keywords": [
      "announce", "promote",
    ]
  }
]

const _contact = [
  {
    "details": { 
      "group": "contact", 
      "sub": "email", 
      "destination": "mailto:info@bytebeacon.com"
    }, 
    "label": "Email us",
    "keywords": [
      "touch"
    ]
  }, {
    "details": { 
      "group": "contact", 
      "sub": "email", 
      "destination": "mailto:?subject=Cool Library!&body=Checkit out here: https://bytebeacon.com"
    }, 
    "label": "Email a friend",
    "keywords": [
      "touch", "friend"
    ]
  }
]

let _data = _internal.concat(_external).concat(_social).concat(_contact).concat(_self_promo);

export default {
  "results": _data,
  "meta": {}
}