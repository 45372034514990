import React from "react";

import SearchInput from "components/SearchInput";
import SpotlightContext from "components/SpotlightContext";


export default () => {
  return (
    <SpotlightContext.Consumer>
      {({ theme }) => (
        <div className={"search-bar "}>
          <SearchInput />
        </div>
    )}
    </SpotlightContext.Consumer>
  );
};
