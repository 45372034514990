import React from "react";

import SpotlightContext from "components/SpotlightContext";

export default () => {
  return (
    <SpotlightContext.Consumer>
      {({ theme }) => (
        <div id="ctrlspace-branding" className={" "}>
          <span className="pull pull-left">
            <kbd className="">ESC</kbd> to exit
          </span>
          <span className="pull pull-right">
            <a href="https://navigator.bytebeacon.com" className="">{'\u26A1'}by Navigator</a>
          </span>
        </div>
      )}
    </SpotlightContext.Consumer>
  );
};
