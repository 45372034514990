import {_self_promo} from './common';


var tagline = encodeURIComponent('Navigator: The new way to navigate through your website.');
var base = encodeURIComponent('https://navigator.bytebeacon.com/');


const _internal = [
  {
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://navigator.bytebeacon.com/"
    }, 
    "label": "Home",
    "keywords": [
      'root'
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://bytebeacon.com/legal/tos"
    }, 
    "label": "Terms of Service",
    "keywords": [
      "tos", "terms"
    ]
  },{
    "details": { 
      "group": "link", 
      "sub": "internal", 
      "destination": "https://bytebeacon.com/legal/privacy"
    }, 
    "label": "Privacy Policy",
    "keywords": [
    ]
  },
]


const _external = [
]

const _social = [
  {
    "details": { 
      "group": "social", 
      "sub": "tweet", 
      "destination": "https://twitter.com/intent/tweet?hashtags=ByteBeacon,Navigator&original_referer=" + base + '&text=' + tagline + '&tw_p=tweetbutton&url=' + base + "&via=catalysticinfo"
    }, 
    "label": "Tweet about #Navigator",
    "keywords": [
      "social", "twitter", "announce", "promote"
    ]
  }, {
    "details": { 
      "group": "social", 
      "sub": "twitter", 
      "destination": "https://twitter.com/intent/follow?screen_name=catalysticinfo"
    }, 
    "label": "Follow on Twitter",
    "keywords": [
      "announce", "promote",
    ]
  }, {
    "details": { 
      "group": "social", 
      "sub": "reddit", 
      "destination": "https://www.reddit.com/user/catalysticinfo"
    }, 
    "label": "Connect on Reddit",
    "keywords": [
      "announce", "promote",
    ]
  }
]

const _contact = [
  {
    "details": { 
      "group": "contact", 
      "sub": "email", 
      "destination": "mailto:info@bytebeacon.com"
    }, 
    "label": "Email us",
    "keywords": [
      "touch"
    ]
  }, {
    "details": { 
      "group": "contact", 
      "sub": "email", 
      "destination": "mailto:?subject=Cool Products!&body=Checkout here: https://navigator.bytebeacon.com"
    }, 
    "label": "Email a friend",
    "keywords": [
      "touch", "friend"
    ]
  }
]

let _data = _internal.concat(_external).concat(_social).concat(_contact).concat(_self_promo);

export default {
  "results": _data,
  "meta": {}
}