import React, { Component } from "react";

import SpotlightContext from "components/SpotlightContext";
import Results from "components/Results";
import SearchBar from "components/SearchBar";
import Branding from "components/Branding";

import SOCIAL from 'constants/social';

import DIVSHOW from 'constants/divshow';
import BYTEBEACON from 'constants/bytebeacon';
import NAVIGATOR from 'constants/navigator';


const DEFAULT_STATE = {
  searchResults: [],
  isOpen: false,
  selectedResultIndex: 0,
  prevSearchTerm: null,
};


function generate_json(navigator_data){

  const _internal = generate_json_internal(navigator_data.internal || []);
  const _external = generate_json_external(navigator_data.external || []);
  const _social = generate_json_social(navigator_data.social || []);
  const _contact = generate_json_contact(navigator_data.contact || []);
  const _promo = generate_json_promo(navigator_data.promo || []);
  let _data = _internal.concat(_external).concat(_social).concat(_contact).concat(_promo);

  return {results: _data};
}

function generate_placeholder_links(type, items_packet){
  let packet_list = [];

  items_packet.items.map(function(item, idx){
    packet_list.push({
      "details": { 
        "group": "link", 
        "sub": type,
        "destination": items_packet.destination_format.replace("PLACEHOLDER", item),
      }, 
      "label": items_packet.prepend_text + ": " + item.charAt(0).toUpperCase() + item.slice(1),
      "keywords": items_packet.keywords,
    })
  })
  return packet_list;
}

function generate_json_internal(data) {
  var data_list = [];

  data.map(function(item, idx){
    if (item.type === 'list'){
      data_list = data_list.concat(generate_placeholder_links('internal', item));
    } else {
      data_list.push({
        "details": { 
          "group": item.type,
          "sub": "internal",
          "destination": item.destination,
        }, 
        "label": item.label,
        "keywords": item.keywords,
      });  
    }
  })

  return data_list;
}

function generate_json_external(data) {
  var data_list = [];

  data.map(function(item, idx){
    if (item.type === 'list'){
      data_list = data_list.concat(generate_placeholder_links('external', item));
    } else {
      data_list.push({
        "details": { 
          "group": item.type, 
          "sub": "external", 
          "destination": item.destination,
        }, 
        "label": item.label,
        "keywords": item.keywords,
      });  
    }
  })

  return data_list;
}

function generate_json_social(data) {
  var data_list = [];

  data.map(function(item, idx){
    data_list.push({
      "details": { 
        "group": "social", 
        "sub": item.type, 
        "destination": item.destination,
      }, 
      "label": item.label,
      "keywords": item.keywords,
    });
  })

  return data_list;
}

function generate_json_contact(data) {
  var data_list = [];

  data.map(function(item, idx){
    data_list.push({
      "details": { 
        "group": "contact", 
        "sub": item.type, 
        "destination": item.destination,
      }, 
      "label": item.label,
      "keywords": item.keywords,
    });
  })

  return data_list;
}

function generate_json_promo(data) {
  var data_list = [];
  
  data.map(function(item, idx){
    data_list.push({
      "details": { 
        "group": "promo", 
        "sub": item.type, 
        "destination": item.destination,
      }, 
      "label": item.label,
      "keywords": item.keywords,
    });
  })

  return data_list;
}


export default class Spotlight extends Component {
  state = {
    ...DEFAULT_STATE,

    toggle: () => {
      this.setState({ isOpen: !this.state.isOpen });
    },
    close: () => {
      this.setState({ isOpen: false });
    },
    open: () => {
      this.setState({ isOpen: true });
    },
    
    clearSearch: (close = false) => {
      this.setState({ ...DEFAULT_STATE, isOpen: !close });
    },

    performOperation: (result) => {
      const {group, sub, destination} = result.details;
      switch (group){
        case "link":
          if (['internal'].indexOf(sub) !== -1){
            window.location.href = destination;
          } else if (['external'].indexOf(sub) !== -1){
            window.open(destination, '_blank');
          } else {
            console.log('unknow operation: ' + group + " - " + sub);
          }
          break;
        case "social":
          if (['tweet', 'twitter', 'reddit', 'facebook', 'instagram'].indexOf(sub) !== -1){
            window.open(destination, '_blank');
            // window.location.href = destination;
          } else {
            console.log('unknow operation: ' + group + " - " + sub);
          }
          break;
        case "contact":
            if (sub === 'email'){
              window.location.href = destination;
            } else {
              console.log('unknow operation: ' + group + " - " + sub);
            }
          break;
        case "promo":
            if (sub === 'navigator'){
              window.open(destination, '_blank');
              // window.location.href = destination;
            } else {
              console.log('unknow operation: ' + group + " - " + sub);
            }
          break;
        default:
          console.log('unknow operation: ' + group + " - " + sub);
          break;
      }

    },

    selectResult: (index) => {
      this.setState({ selectedResultIndex: index });
    },

    selectUp: () => {
      const { searchResults, selectedResultIndex, selectResult } = this.state;
      if (selectedResultIndex > 0) {
        selectResult(selectedResultIndex - 1);
        return;
      }
      selectResult(searchResults.length - 1);
    },
    selectDown: () => {
      const { searchResults, selectedResultIndex, selectResult } = this.state;
      if (selectedResultIndex < searchResults.length - 1) {
        selectResult(selectedResultIndex + 1);
        return;
      }
      selectResult(0);
    },

    handleKeyUp: input => {
      const { clearSearch, performSearch } = this.state;
      if (!input) {
        clearSearch();
      } else {
        performSearch(input);
      }
    },

    handleKeyDown: event => {
      const { selectUp, selectDown, clearSearch, searchResults, selectedResultIndex, performOperation } = this.state;

      switch (event.key) {
        case "ArrowUp":
          selectUp();
          event.preventDefault();
          break;

        case "ArrowDown":
          selectDown();
          event.preventDefault();
          break;

        case "Tab":
          if (event.shiftKey) {
            selectUp();
          } else {
            selectDown();
          }
          event.preventDefault();
          break;

        case "Enter":
          if (selectedResultIndex > -1 & selectedResultIndex < searchResults.length){
            performOperation(searchResults[selectedResultIndex]);
          }
          event.preventDefault();
          break;

        case "Escape":
          clearSearch(true);
          event.preventDefault();
          break;
      }
    },

    performSearch: async term => {
      const { prevSearchTerm, data, api_key, navigator_data } = this.state;

      if (term === undefined || term === null || term === ''){
        this.setState({searchResults: [],})
        return;
      }

      if (term === prevSearchTerm){
        return;
      }

      let json = null;
      if (api_key === null){
        json = {
          'bytebeacon': BYTEBEACON,
          'divshow': DIVSHOW,
          'navigator': NAVIGATOR,
        }[data];

      } else if (api_key === 'demo_social'){
        json = SOCIAL.populate(data);

      } else if (navigator_data !== undefined){
        json = generate_json(navigator_data.data);

      } else {
        // json = {
        //   "898xbei5blm1" : CUST_898xbei5blm1,
        //   "mufpgfu44ecp": CUST_mufpgfu44ecp,
        //   "adaxld8y512t": CUST_adaxld8y512t,
        // }[api_key];
        console.log('Something went wrong!');
      }

      const search_text = term;
      function check(searchResult) {
        var search_str_data = searchResult.details.destination.toLowerCase() 
          + ' ' + searchResult.details.group.toLowerCase() 
          + ' ' + searchResult.details.sub.toLowerCase() 
          + ' ' + searchResult.label.toLowerCase() 
          + ' ' + searchResult.keywords.join(' ');
        return (search_str_data.includes(search_text) || search_text === null);  
      }

      const searchResults = (term === '/')? json.results: json.results.filter(check);
      this.setState({
        prevSearchTerm: term,
        searchResults: searchResults,
        selectedResultIndex: 0,
      });
    }
  };

  toggle = () => {
    const state = this.state;
    state.isOpen = !this.state.isOpen;
    this.setState(state);
  }

  close = () => {
    const state = this.state;
    state.isOpen = false;
    this.setState(state);
  }

  open = () => {
    const state = this.state;
    state.isOpen = true;
    this.setState(state);
  }

  _listenKey = event => {
    const isCtrlSpace = event.keyCode === 32 && event.ctrlKey;
    if (!isCtrlSpace) {
      return;
    }

    this.state.toggle();
  };

  _close = event => {
    if (document.getElementById('ctrlspace').contains(event.target)){
      // Clicked in box
    } else {
      // this.state.clearSearch(true);
      this.state.close();
    }
};

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this._listenKey);
    document.body.removeEventListener('click', this._close);
  }

  async componentDidMount() {
    document.body.addEventListener("keydown", this._listenKey);
    document.body.addEventListener('click', this._close);
    this.state.theme = this.props.theme;
    this.state.data = this.props.data;
    this.state.api_key = this.props.api_key;

    const api_key = this.props.api_key;
    const res = await fetch(
      `https://navigator-api.bytebeacon.com/api/v1/navigator_key/${api_key}`
    );
    const navigator_data = await res.json();
    this.state.navigator_data = navigator_data;
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    const theme = this.state.theme;

    return (
      <div id="ctrlspace-app" className={" bg-"+theme.mode+"-"+theme.colour}>
        <SpotlightContext.Provider value={this.state}>
          <SearchBar />
          <Results />
          <Branding />
        </SpotlightContext.Provider>
      </div>
    );
  }
}
