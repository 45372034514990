import React from "react";
// import { debounce } from "lodash-es";

import SpotlightContext from "components/SpotlightContext";


class SearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    // this._performSearch = debounce(this._performSearch, 500);
  }

  _performSearch = searchTerm => {
    this.props.keyUpFunction(searchTerm);
  };

  performSearch = event => {
    this._performSearch(event.target.value);
  };

  render() {
    const {mode, colour} = this.props.theme;
    return (
      <input 
        type="text" 
        className={"search-input "}
        placeholder="Press / or enter your search term"
        autoFocus={true}
        onKeyUp={this.performSearch} 
        onKeyDown={this.props.keyDownFunction} />
    );
  }
}

export default () => (
  <SpotlightContext.Consumer>
    {({ handleKeyUp, handleKeyDown, theme }) => (
      <SearchInput
        keyUpFunction={handleKeyUp}
        keyDownFunction={handleKeyDown}
        theme={theme}
      />
    )}
  </SpotlightContext.Consumer>
);
