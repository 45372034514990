import {_self_promo} from './common';


function _social(data){

  let item_list = [];

  if (data.twitter){
    const item = {
      "details": { 
        "group": "social", 
        "sub": "twitter", 
        "destination": "https://twitter.com/intent/follow?screen_name=" + data.twitter
      }, 
      "label": "Follow on Twitter",
      "keywords": [
        "announce", "promote",
      ]
    }
    item_list.push(item);
  }

  if (data.facebook){
    const item = {
      "details": { 
        "group": "social", 
        "sub": "facebook", 
        "destination": "https://www.facebook.com" + data.facebook
      }, 
      "label": "Connect on Facebook",
      "keywords": [
        "announce", "promote",
      ]
    }
    item_list.push(item);
  }

  if (data.youtube){
    const item = {
      "details": { 
        "group": "social", 
        "sub": "youtube", 
        "destination": "https://www.youtube.com/user/" + data.youtube
      }, 
      "label": "Follow on Youtube",
      "keywords": [
        "announce", "promote",
      ]
    }
    item_list.push(item);
  }

  if (data.linked_in){
    const item = {
      "details": { 
        "group": "social", 
        "sub": "linked_in", 
        "destination": "https://www.linkedin.com/in/" + data.linked_in
      }, 
      "label": "Connect on LinkedIn",
      "keywords": [
        "announce", "promote",
      ]
    }
    item_list.push(item);
  }

  if (data.instagram){
    const item = {
      "details": { 
        "group": "social", 
        "sub": "instagram", 
        "destination": "https://www.instagram.com/" + data.instagram
      }, 
      "label": "Follow on Instagram",
      "keywords": [
        "announce", "promote",
      ]
    }
    item_list.push(item);
  }

  return item_list
}


export default {
  populate(data){
    const self_data = {
      "twitter": "catalysticinfo",
      "facebook": "catalyst.ic.98",
      "linked_in": "catalyst-ic-119b3016b",
      "instagram": "catalysticinfo",
    }
    return {
      "results": _social(self_data).concat(_self_promo),
      "meta": {}    
    }
  }
}