import React from "react";

import ResultList from "components/ResultList";
import ResultDetails from "components/ResultDetails";
import SpotlightContext from "components/SpotlightContext";


export default () => (
  <SpotlightContext.Consumer>
    {({ searchResults, theme }) => {
      const hasResults = searchResults.length > 0;

      if (hasResults){
        return (
          <div className="">
            <ResultDetails />
            <div className={"search-results"}>
              <ResultList />
            </div>
          </div>
        )
      }

      return ('');
    }}
  </SpotlightContext.Consumer>
);
