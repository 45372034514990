
export const _self_promo = [
  {
    "details": { 
      "group": "promo", 
      "sub": "navigator", 
      "destination": "https://navigator.bytebeacon.com"
    }, 
    "label": "Get this widget on your website!",
    "keywords": [
      "what is this", "navigator", "hello", "demo", "intro", 
    ]
  },
]
