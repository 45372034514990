import React from 'react'
import ReactDOM from 'react-dom'

import Spotlight from "./Spotlight";

import './style.scss';


// https://github.com/kentcdodds/genie
// https://github.com/JoaoCnh/react-spotlight
// https://github.com/johnpolacek/react-widget-library-starter


const DEFAULT = {
  api_key: null,
  theme: DEFAULT_THEME,
  data: null,
}

const DEFAULT_THEME = {
  mode: 'dark',
  colour: 'blue',
}

const NAMESPACE = 'ctrlspace';


function createWidget(options){

  const colours = ["red", "blue", "green", "violet", "purple", "black", "yellow", "orange", "gold",];
  if (colours.indexOf(options.theme.colour) === -1){
    console.log(options.theme);
    options.theme.colour = 'black';
    console.log('resetting to black');
  }

  let opts_theme = Object.assign({}, DEFAULT_THEME, options.theme);
  let opts = Object.assign({}, DEFAULT, options);
  opts.theme = opts_theme;
  const theme = opts.theme;

  var widget = document.createElement('div');
  widget.id = NAMESPACE;
  // widget.id = NAMESPACE+'-widget';
  // widget.classList.add(NAMESPACE);
    
  var trigger = document.createElement('div');
  trigger.id = NAMESPACE+'-trigger';
  trigger.innerHTML = '<a class="'+"text-"+theme.colour+'" href="javascript:;" onClick="window.bb_navigator.toggle()" >Navigate with <kbd class="">ctrl</kbd> + <kbd class="">space</kbd></a>';
  widget.appendChild(trigger);
  
  var app = document.createElement('div');
  app.id = NAMESPACE+'-app-div';
  app.classList.add('bg-'+theme.mode+'-'+theme.colour+'-shadow');
  widget.appendChild(app);

  document.body.prepend(widget);

  return ReactDOM.render(
    <Spotlight api_key={opts.api_key} theme={opts.theme} data={opts.data} ref={(bb_navigator) => {window.bb_navigator = bb_navigator}} />,
    document.getElementById(NAMESPACE+'-app-div')
  )
}

export { createWidget };