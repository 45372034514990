import React from "react";

import SpotlightContext from "components/SpotlightContext";


export default class SearchResult extends React.Component {
  componentDidUpdate(){
    const focused = document.querySelector('.result-item-selected')
    if (focused){
      focused.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }

  render (){
    return (
      <SpotlightContext.Consumer>
        {({ searchResults, selectedResultIndex , selectResult, performOperation, theme }) => (
          <ul className={"result-box"}>
            {searchResults.map((searchResult, idx) => {
              const img_class = {
                "link": {
                  "internal": "img-result-group-link-int",
                  "external": "img-result-group-link-ext",
                },
                "social":{
                  "tweet": "img-result-group-tw",
                  "twitter": "img-result-group-tw",
                  "fb": "img-result-group-fb",
                  "facebook": "img-result-group-fb",
                  "reddit": "img-result-group-rd",
                  "youtube": "img-result-group-yt",
                  "linked_in": "img-result-group-li",
                  "linkedin": "img-result-group-li",
                  "instagram": "img-result-group-in",
                  "slack": "img-result-group-sl",
                  "shopify": "img-result-group-sh",
                  "github": "img-result-group-gh",
                },
                "contact": {
                  "email": "img-result-group-email",
                  "page": "img-result-group-link-int",
                },
                "promo": {
                  "navigator": "img-result-group-navigator",
                },
              }[searchResult.details.group][searchResult.details.sub];

              const highlight = idx === selectedResultIndex;

              return (
                <li key={'key_' + idx} className={"result-item " + ((highlight)? (' result-item-selected '+ " bg-"+theme.mode+"-black"): '')}>
                  <div 
                    className="result-label"
                    onClick={() => performOperation(searchResult)}
                    onMouseEnter={() => selectResult(idx)}
                  >
                    <span className={"img-result-group " + img_class}></span>
                    &nbsp;&nbsp;
                    <span className="">{searchResult.label}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </SpotlightContext.Consumer>
    )
  }
};
